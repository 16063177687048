import React, { useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { selectConfTab, ViewList } from "../../../features/configSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AXIOS_PUT } from "../../../config/axios";
import { PUT_CONFIG_URL } from "../../../helper/Url";
import { SetIsTriggered } from "../../../features/setLoaderSlice";
import { toast, ToastContainer } from "react-toastify";
import { selectLanguage } from "../../../features/LanguageSlice";
import { ClipLoader } from "react-spinners";

function EditConfig() {
  const dispatch = useDispatch();
  const getconfig = useSelector(selectConfTab).viewedit?.id?.payload;
  const [domain, setDomain] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [fm_host, setFm_host] = useState("");
  const [fm_file, setFm_file] = useState("");
  const [fm_username, setFm_username] = useState("");
  const [fm_password, setFm_password] = useState("");
  const [email_password, setEmail_password] = useState("");
  const [email_address, setEmail_address] = useState("");
  const [email_host, setEmail_host] = useState("");
  const [app_name, setApp_name] = useState("");
  const [email_encryption, setEmail_encryption] = useState("");
  const [email_port, setEmail_port] = useState("");
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  const languageValue = useSelector(selectLanguage);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setDomain(getconfig.domain);
    setSubdomain(getconfig.subdomain);
    setFm_host(getconfig.fm_host);
    setFm_file(getconfig.fm_file);
    setFm_username(getconfig.fm_username);
    setFm_password(getconfig.fm_password);
    setEmail_password(getconfig.email_password);
    setEmail_address(getconfig.email_address);
    setEmail_host(getconfig.email_host);
    setApp_name(getconfig.app_name);
    setEmail_encryption(getconfig.email_encryption);
    setEmail_port(getconfig.email_port);
  }, []);

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    let data = {
      domain,
      subdomain,
      email_password,
      fm_host,
      fm_file,
      fm_username,
      fm_password,
      email_address,
      email_host,
      app_name,
      email_encryption,
      email_port,
    };
    setLoading(true);
    AXIOS_PUT(`${PUT_CONFIG_URL}${getconfig.id}`, { ...data }, token)
      .then((result) => {
        languageValue === "En"
          ? toast("Configuration Updated Successful!")
          : toast("Configuration mise à jour réussie !");
        setTimeout(() => {
          dispatch(SetIsTriggered());
          dispatch(ViewList());
        }, 4000);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        languageValue === "En"
          ? toast("Something Went wrong!")
          : toast("Quelque chose s'est mal passé!");
      });
  };
  return (
    <div className="edit_config">
      <ToastContainer />
      <h4>
        Edit Configuration{" "}
        <button className="closebtn" onClick={() => dispatch(ViewList())}>
          <CloseIcon />
        </button>
      </h4>
      <form onSubmit={handleSubmitEdit}>
        <div className="group">
          <div className="single">
            <label htmlFor="">Domain</label>
            <input
              type="text"
              required
              defaultValue={getconfig?.domain}
              name="domain"
              onChange={(e) => setDomain(e.target.value)}
            />
          </div>
          <div className="single">
            <label htmlFor="">Subdomain</label>
            <input
              type="text"
              required
              name="subdomain"
              onChange={(e) => setSubdomain(e.target.value)}
              defaultValue={getconfig?.subdomain}
            />
          </div>{" "}
          <div className="single">
            <label htmlFor="">Filemaker host</label>
            <input
              type="text"
              required
              name="fm_host"
              onChange={(e) => setFm_host(e.target.value)}
              defaultValue={getconfig?.fm_host}
            />
          </div>{" "}
        </div>
        <div className="group">
          <div className="single">
            <label htmlFor="">Filemaker file</label>
            <input
              type="text"
              required
              name="fm_file"
              onChange={(e) => setFm_file(e.target.value)}
              defaultValue={getconfig?.fm_file}
            />
          </div>
          <div className="single">
            <label htmlFor="">Filemaker username</label>
            <input
              type="text"
              required
              name="fm_username"
              onChange={(e) => setFm_username(e.target.value)}
              defaultValue={getconfig?.fm_username}
            />
          </div>{" "}
          <div className="single">
            <label htmlFor="">Filemaker password</label>
            <input
              type="text"
              required
              name="fm_password"
              onChange={(e) => setFm_password(e.target.value)}
              defaultValue={getconfig?.fm_password}
            />
          </div>{" "}
        </div>
        <div className="group">
          <div className="single">
            <label htmlFor="">Email password</label>
            <input
              type="text"
              required
              name="email_password"
              onChange={(e) => setEmail_password(e.target.value)}
              defaultValue={getconfig?.email_password}
            />
          </div>
          <div className="single">
            <label htmlFor="">Email address</label>
            <input
              type="email"
              required
              name="email_address"
              onChange={(e) => setEmail_address(e.target.value)}
              defaultValue={getconfig?.email_address}
            />
          </div>{" "}
          <div className="single">
            <label htmlFor="">Email host</label>
            <input
              type="text"
              required
              name="email_host"
              onChange={(e) => setEmail_host(e.target.value)}
              defaultValue={getconfig?.email_host}
            />
          </div>{" "}
        </div>
        <div className="group">
          <div className="single">
            <label htmlFor="">App name</label>
            <input
              type="text"
              required
              name="app_name"
              onChange={(e) => setApp_name(e.target.value)}
              defaultValue={getconfig?.app_name}
            />
          </div>
          <div className="single">
            <label htmlFor="">Email encryption</label>
            <input
              type="text"
              required
              name="email_encryption"
              onChange={(e) => setEmail_encryption(e.target.value)}
              defaultValue={getconfig?.email_encryption}
            />
          </div>{" "}
          <div className="single">
            <label htmlFor="">Email port</label>
            <input
              type="text"
              required
              name="email_port"
              onChange={(e) => setEmail_port(e.target.value)}
              defaultValue={getconfig?.email_port}
            />
          </div>{" "}
        </div>
        <div className="action">
          <button>
            {" "}
            {Loading ? (
              <ClipLoader size={20} color="rgb(121 173 192)" />
            ) : (
              "save"
            )}
          </button>
          <button
            type="button"
            className="cancel"
            onClick={() => dispatch(ViewList())}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditConfig;

export const Container = styled.div`
  height: 100vh;
`;
