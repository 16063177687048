import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {
    first_name: "N/a",
    last_name: "N/a",
    email: "N/a",
    address: "N/a",
    contact_with_email: "N/a",
    contact_with_sms: "N/a",
    contact_with_whatsapp: "N/a",
    city: "N/a",
    postalcode: "N/a",
  },
};

export const getUserSlice = createSlice({
  name: "getuserstore",
  initialState,
  reducers: {
    UserInfo: (state, payload) => {
      state.userData = payload;
    },
  },
});

export const { UserInfo } = getUserSlice.actions;

export const selectUserInfo = (state) => state.getuserstore.userData;

export default getUserSlice.reducer;
