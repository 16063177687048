import React, { useState } from "react";
import styled from "styled-components";
import Translate from "../config/Translate";
import Loader from "../helper/Loader";

const VerifyEmailContainer = () => {
  const [showLoader, setShowLoader] = useState(true);
  return (
    <Container>
      <PageIntro>
        {/* <ImgContainer src={Logo} alt="logo" /> */}
        <Title>
          <Translate Word="page.verify.message.title"></Translate>
        </Title>
        <p>
          <Translate Word="page.verify.message.subtitle"></Translate>
        </p>
      </PageIntro>
      <Form>
        <Button> {showLoader ? <Loader /> : "Verify Email"}</Button>
      </Form>
      <Copyright>©2023 Digimidi</Copyright>
    </Container>
  );
};

export default VerifyEmailContainer;

const Container = styled.div`
  position: relative;
  color: rgba(0, 0, 0, 0.85);
`;
const ImgContainer = styled.img`
  width: 150px;
  object-fit: cover;
`;
const PageIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
`;
const Title = styled.h3`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 5px;
  font-size: 13px;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 4px;
  height: 42px;
  padding: 6px 10px;
  line-height: 1.5;
  color: rgb(89, 89, 89);
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(233, 233, 233);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  margin-bottom: 9px;
  outline: none;
  margin-top: 14px;
  &:focus {
    border-color: #40a9ff;
  }
  &[type="checkbox"] {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`;
const Form = styled.form``;
const FormAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Button = styled.button`
  width: 100%;
  padding: 10px 16px;
  height: 40px;
  cursor: pointer;
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  font-weight: 500;
  border-radius: 4px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 68px;
`;
const ForgetContainer = styled.div`
  display: grid;
  place-content: center;
  text-align: center;
  margin: 30px 0;
`;
const Copyright = styled.div`
  color: rgba(0, 0, 0, 0.45);
  margin-top: 35px;
  text-align: center;
`;
const Label = styled.label`
  display: flex;
  align-items: start;
`;

const ForgetLink = styled.p`
  color: rgb(121, 121, 121);
  margin-bottom: 15px;
  font-size: 13px;
  &:hover {
    color: #1890ff;
    cursor: pointer;
  }
`;
const SignUpLink = styled.p``;
const SingUpAction = styled.span`
  color: #1890ff;
  margin-left: 5px;
  cursor: pointer;
`;
const Perror = styled.p`
  color: #d03333e0;
  font-size: 14px;
`;
const ActionLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
`;
