import React from "react";
import styled from "styled-components";

const Footer = () => {
  return <FooterContainer>©2023 Digimidi</FooterContainer>;
};

export default Footer;

const FooterContainer = styled.footer`
  background: rgb(255, 255, 255);
  text-align: center;
  border-top: 1px solid rgb(237, 237, 237);
  font-size: 13px;
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  position: fixed;
  bottom: 0;
  margin-top: 50px;
  width: 100%;
  background: white;
  left: 0;
  z-index: 1;
`;
