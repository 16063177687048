import { Base_Url } from "../config/variables.js";

export const REGISTER_URL = `/register`;
export const LOGIN_URL = `/login`;
export const POST_FORGET_PASSWORD_URL = "/forgot-password";
export const RESET_PASSWORD = `/reset-password`;
export const VERIFY_EMAIL = `/email/verification-notification`;
export const GET_VERIFY_EMAIL = `/email/:id/:hash`;
export const POST_CHANGE_PASSWORD_URL = `/change-password`;
export const UPDATE_PROFILE_URL = `/profile`;
export const GET_PROFILE_URL = `/profile`;
export const POST_PROFILE_AVATAR = `/profile-avatar`;
export const POST_EMAIL_VERIFICATION_NOTIFICATION = `/email/verification-notification`;
export const POST_PASSWORD_RESET = "/reset-password";
export const ADD_CONFIG_URL = "/config";
export const GET_CONFIGS_URL = "/configs";
export const PUT_CONFIG_URL = "/config/";
export const DELETE_CONFIG_URL = "/config/";
export const GET_CONFIG_URL = "/config/";

//Pets

export const GET_PETS_URL = "/patients";
export const GET_PET_URL = "/patient/";
export const ADD_PET_URL = "/patient";
export const UPDATE_PET_URL = "/patient/";
export const GET_PET_FILE_URL = "/pets/";
export const GET_DOWNLOAD_FILE_URL = `${Base_Url}/api/petFiles/`;
export const ADD_PET_FILE = `${Base_Url}/api/postPetFiles/`;

//Medical records

export const GET_MEDICAL_RECORDS = `/medicals`;
export const GET_MEDICAL_REPORT = `/reportRecords`;
export const GET_MEDICAL_BIOCHIMIE = `/biochimieRecords`;
export const GET_MEDICAL_HEMATOLOGIE = `/hematologieRecords`;
export const GET_MEDICAL_VIGNETTERECORDS = `/vignetteRecords`;
export const GET_MEDICAL_PJRECORDS = `/pjRecords`;
export const DOWNLOAD_MEDIDCAL_REPORT = `${Base_Url}/api/medical/download/`;
export const GET_SINGLE_MEDICAL_REPORT = `${Base_Url}/api/medical/`;
