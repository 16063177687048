import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MenuList } from "../../../assets/icons/menuList.svg";
import { ReactComponent as Tab } from "../../../assets/icons/Tab.svg";
import GridView from "./GridView";
import ListView from "./ListView";
import Translate from "../../../config/Translate";
import { GET_PETS_URL } from "../../../helper/Url";
import { AXIOS_GET } from "../../../config/axios";
const PetsContainer = () => {
  const [showPets, setshowPets] = useState({
    grid: true,
    list: false,
  });
  const [petsData, setpPetsData] = useState([]);
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;

  const navigate = useNavigate();

  const gridView = (e) => {
    let btns = document.querySelectorAll(".dataList");
    btns.forEach((btn) => {
      btn.classList.remove("active");
    });
    e.target.classList.add("active");
    setshowPets({ grid: true, list: false });
  };
  const listView = (e) => {
    let btns = document.querySelectorAll(".dataList");
    btns.forEach((btn) => {
      btn.classList.remove("active");
    });
    e.target.classList.add("active");
    setshowPets({ grid: false, list: true });
  };
  const getAllPets = () => {
    AXIOS_GET(GET_PETS_URL, token)
      .then((res) => {
        setpPetsData(res.data.data);
      })
      .catch((e) => {});
  };
  useEffect(() => {
    getAllPets();
  }, []);

  return (
    <div className="pet_container_action">
      <div className="Intro_header">
        <div className="page_name">
          <h1>
            <Translate Word="page.pets.Mypets"></Translate>
          </h1>
        </div>
        <div className="page_action">
          <div className="list_switcher">
            <button className="dataList active" onClick={gridView}>
              <Tab></Tab>
            </button>
            <button className="dataList" onClick={listView}>
              <MenuList></MenuList>
            </button>
          </div>
          <div className="add_new_pet">
            <button onClick={() => navigate("/dashboard/pets/new")}>
              + <Translate Word="page.pets.addPet"></Translate>
            </button>
          </div>
        </div>
      </div>
      <div className="data_container">
        {showPets.grid && <GridView Data={petsData} />}
        {showPets.list && <ListView Data={petsData} />}
      </div>
    </div>
  );
};

export default PetsContainer;
