import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { selectConfTab, ViewList } from "../../../features/configSlice";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { GET_CONFIG_URL } from "../../../helper/Url";
import { useEffect } from "react";
import { AXIOS_GET } from "../../../config/axios";
import { useState } from "react";

function ViewConf() {
  const dispatch = useDispatch();
  const getconfig = useSelector(selectConfTab).viewsingle?.id?.payload;
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  const [confId, setconfId] = useState("null");
  const getAllConfing = () => {
    AXIOS_GET(`${GET_CONFIG_URL}${getconfig.id}`, token)
      .then((res) => {
        setconfId(res.data.data.config_id);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getAllConfing();
  }, [getconfig.id]);
  const coppyRegisterLink = async () => {
    let linkValue = document.querySelector(".registerLink");
    let cloneLink = linkValue.value;
    try {
      await navigator.clipboard.writeText(linkValue.value);
      linkValue.value = "Copied !";
      setTimeout(() => {
        linkValue.value = cloneLink;
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const sendRegisterLink = (email) => {
    let linkValue = document.querySelector(".registerLink").value;
    let formattedBody = `Welcome to your clinic\nHere is your Registration Link. \n\n ${linkValue} \n\n For any registration issues contact me on antoine@gmail.com!`;
    let mailToLink =
      `mailto:${email}?body=` + encodeURIComponent(formattedBody);
    window.location.href = mailToLink;
  };

  return (
    <div className="add_new_config">
      <h4>
        Edit Configuration{" "}
        <button className="closebtn" onClick={() => dispatch(ViewList())}>
          <CloseIcon />
        </button>
      </h4>
      <div className="group">
        <div className="single">
          <label htmlFor="">Domain</label>
          <input type="text" required disabled value={getconfig?.domain} />
        </div>
        <div className="single">
          <label htmlFor="">Subdomain</label>
          <input type="text" required disabled value={getconfig?.subdomain} />
        </div>{" "}
        <div className="single">
          <label htmlFor="">Filemaker host</label>
          <input type="text" required disabled value={getconfig?.fm_host} />
        </div>{" "}
      </div>
      <div className="group">
        <div className="single">
          <label htmlFor="">Filemaker file</label>
          <input type="text" required disabled value={getconfig?.fm_file} />
        </div>
        <div className="single">
          <label htmlFor="">Filemaker username</label>
          <input type="text" required disabled value={getconfig?.fm_username} />
        </div>{" "}
        <div className="single">
          <label htmlFor="">Filemaker password</label>
          <input type="text" required disabled value={getconfig?.fm_password} />
        </div>{" "}
      </div>
      <div className="group">
        <div className="single">
          <label htmlFor="">Email password</label>
          <input
            type="text"
            required
            disabled
            value={getconfig?.email_password}
          />
        </div>
        <div className="single">
          <label htmlFor="">Email address</label>
          <input
            type="text"
            required
            disabled
            value={getconfig?.email_address}
          />
        </div>{" "}
        <div className="single">
          <label htmlFor="">Email host</label>
          <input type="text" required disabled value={getconfig?.email_host} />
        </div>{" "}
      </div>
      <div className="group">
        <div className="single">
          <label htmlFor="">App name</label>
          <input type="text" required disabled value={getconfig?.app_name} />
        </div>
        <div className="single">
          <label htmlFor="">Email encryption</label>
          <input
            type="text"
            required
            disabled
            value={getconfig?.email_encryption}
          />
        </div>{" "}
        <div className="single">
          <label htmlFor="">Email port</label>
          <input type="text" required disabled value={getconfig?.email_port} />
        </div>
      </div>
      <div className="single copyLink">
        <label htmlFor="" className="copyLink">
          Registration Link
        </label>
        <input
          className="registerLink"
          type="text"
          required
          disabled
          value={`${getconfig?.subdomain}/register/${confId}`}
        />
        <span onClick={coppyRegisterLink}>
          <ContentCopyIcon />
        </span>
        <span onClick={() => sendRegisterLink(getconfig?.email_address)}>
          <MailOutlineIcon />
        </span>
      </div>
      <div className="action">
        <button className="cancel" onClick={() => dispatch(ViewList())}>
          Cancel
        </button>
      </div>
    </div>
  );
}

export default ViewConf;
