import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ViewList } from "../../../features/configSlice";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { AXIOS_POST } from "../../../config/axios";
import { ADD_CONFIG_URL } from "../../../helper/Url";
import { SetIsTriggered } from "../../../features/setLoaderSlice";
import { toast, ToastContainer } from "react-toastify";
import { selectLanguage } from "../../../features/LanguageSlice";
import { ClipLoader } from "react-spinners";

const AddNewConfig = () => {
  const dispatch = useDispatch();
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  const domain = useRef();
  const subdomain = useRef();
  const fm_host = useRef();
  const fm_file = useRef();
  const fm_username = useRef();
  const fm_password = useRef();
  const email_password = useRef();
  const email_host = useRef();
  const email_encryption = useRef();
  const email_address = useRef();
  const app_name = useRef();
  const email_port = useRef();
  const languageValue = useSelector(selectLanguage);
  const [Loading, setLoading] = useState(false);

  const CreateConf = (e) => {
    e.preventDefault();
    let data = {};
    data.domain = domain.current.value;
    data.subdomain = subdomain.current.value;
    data.fm_host = fm_host.current.value;
    data.fm_file = fm_file.current.value;
    data.fm_username = fm_username.current.value;
    data.fm_password = fm_password.current.value;
    data.email_password = email_password.current.value;
    data.email_host = email_host.current.value;
    data.email_encryption = email_encryption.current.value;
    data.email_address = email_address.current.value;
    data.app_name = app_name.current.value;
    data.email_port = email_port.current.value;
    setLoading(true);
    AXIOS_POST(ADD_CONFIG_URL, data, token)
      .then((result) => {
        setLoading(false);
        languageValue === "En"
          ? toast("Configuration Added Successful!")
          : toast("Configuration ajoutée réussie!");
        setTimeout(() => {
          dispatch(SetIsTriggered());
          dispatch(ViewList());
        }, 4000);
      })
      .catch((e) => {
        languageValue === "En"
          ? toast("Something Went wrong!")
          : toast("Quelque chose s'est mal passé!");
        setLoading(false);
      });
  };
  return (
    <div className="edit_config">
      <ToastContainer />
      <h4>
        Add Configuration{" "}
        <button className="closebtn" onClick={() => dispatch(ViewList())}>
          <CloseIcon />
        </button>
      </h4>
      <form onSubmit={CreateConf}>
        <div className="group">
          <div className="single">
            <label htmlFor="">Domain</label>
            <input type="text" ref={domain} required />
          </div>
          <div className="single">
            <label htmlFor="">Subdomain</label>
            <input type="text" ref={subdomain} required />
          </div>{" "}
          <div className="single">
            <label htmlFor="">Filemaker host</label>
            <input type="text" ref={fm_host} required />
          </div>{" "}
        </div>
        <div className="group">
          <div className="single">
            <label htmlFor="">Filemaker file</label>
            <input type="text" ref={fm_file} required />
          </div>
          <div className="single">
            <label htmlFor="">Filemaker username</label>
            <input type="text" ref={fm_username} required />
          </div>{" "}
          <div className="single">
            <label htmlFor="">Filemaker password</label>
            <input type="text" ref={fm_password} required />
          </div>{" "}
        </div>
        <div className="group">
          <div className="single">
            <label htmlFor="">Email password</label>
            <input type="text" ref={email_password} required />
          </div>
          <div className="single">
            <label htmlFor="">Email address</label>
            <input type="email" ref={email_address} required />
          </div>{" "}
          <div className="single">
            <label htmlFor="">Email host</label>
            <input type="text" ref={email_host} required />
          </div>{" "}
        </div>
        <div className="group">
          <div className="single">
            <label htmlFor="">App name</label>
            <input type="text" ref={app_name} required />
          </div>
          <div className="single">
            <label htmlFor="">Email encryption</label>
            <input type="text" ref={email_encryption} required />
          </div>{" "}
          <div className="single">
            <label htmlFor="">Email port</label>
            <input type="text" ref={email_port} required />
          </div>{" "}
        </div>
        <div className="action">
          <button>
            {" "}
            {Loading ? (
              <ClipLoader size={20} color="rgb(121 173 192)" />
            ) : (
              "save"
            )}
          </button>
          <button
            type="button"
            className="cancel"
            onClick={() => dispatch(ViewList())}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewConfig;
